





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    AggregateTransaction,
    UInt64,
    Mosaic,
    TransactionStatus,
    TransactionType,
    TransferTransaction,
    UnresolvedMosaicId,
} from 'symbol-sdk';
import { AccountModel } from '@/core/database/entities/AccountModel';
import { optinImages } from '@/views/resources/Images';
import TransactionDetails from '@/components/TransactionDetails/TransactionDetails.vue';
import { TransactionView } from '@/core/transactions/TransactionView';
import MosaicAmountDisplay from '@/components/MosaicAmountDisplay/MosaicAmountDisplay.vue';

@Component({
    components: {
        MosaicAmountDisplay,
        TransactionDetails,
    },
})
export default class TransactionOptinPayoutDetails extends Vue {
    @Prop({ required: true }) readonly transaction: AggregateTransaction;
    @Prop({ required: true }) readonly currentAccount: AccountModel;

    private OptinLogo = optinImages.optinLogo;
    private isDetailsShown = false;
    private isExpanded = false;
    private isLoading = false;
    private transactionDetails: AggregateTransaction = null;

    private get referenceInnerTransactions(): Array<TransferTransaction> {
        const currentAddress = this.currentAccount.address;
        const innerTransactions = this.transaction.innerTransactions.length
            ? this.transaction.innerTransactions
            : this.transactionDetails?.innerTransactions || [];

        const transactions = innerTransactions.filter(
            (innerTransaction) =>
                innerTransaction.type === TransactionType.TRANSFER &&
                (innerTransaction as TransferTransaction).recipientAddress?.plain() === currentAddress &&
                (innerTransaction as TransferTransaction).mosaics?.length,
        );

        return transactions as Array<TransferTransaction>;
    }

    private get hasTransfers(): boolean {
        return !!this.referenceInnerTransactions.length;
    }

    private get transferredMosaics(): Array<Mosaic> {
        return this.referenceInnerTransactions.map((transaction) => transaction.mosaics[0]);
    }

    private get amount(): UInt64 {
        let sumAmount = UInt64.fromNumericString('0');
        this.transferredMosaics?.forEach((mosaic) => (sumAmount = sumAmount.add(mosaic.amount)));

        return sumAmount;
    }

    private get mosaicId(): UnresolvedMosaicId {
        return this.transferredMosaics[0]?.id;
    }

    private get NISAddresses(): Array<string> {
        let NISAddresses = [];

        try {
            NISAddresses = this.referenceInnerTransactions
                .map((transaction) => JSON.parse(transaction?.message.payload || '{}').nisAddress)
                .filter((nisAddress) => !!nisAddress);
        } catch (e) {
            console.log('Opt-in payment transaction. Failed to get NIS1 address', e);
        }

        return NISAddresses;
    }

    public async fetchTransactionDetails() {
        this.isLoading = true;

        try {
            const transactionHash = this.transaction.transactionInfo.hash;
            const transactionStatus: TransactionStatus = (await this.$store.dispatch('transaction/FETCH_TRANSACTION_STATUS', {
                transactionHash,
            })) as TransactionStatus;

            if (transactionStatus.group !== 'failed') {
                this.transactionDetails = (await this.$store.dispatch('transaction/LOAD_TRANSACTION_DETAILS', {
                    group: transactionStatus.group,
                    transactionHash,
                })) as AggregateTransaction;
            }
        } catch (error) {
            console.log(error);
        }

        this.isLoading = false;
    }

    private onDetailsClick() {
        this.isDetailsShown = !this.isDetailsShown;
        setTimeout(() => {
            this.isExpanded = this.isDetailsShown;
        }, 500);
    }

    private mounted() {
        this.isDetailsShown = false;
        this.isExpanded = false;
        this.isLoading = false;
        this.fetchTransactionDetails();
    }
}
