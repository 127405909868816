


















import { SignerBaseFilterTs } from './SignerBaseFilterTs';
export default class SignerBaseFilter extends SignerBaseFilterTs {}
