




















































































































































































































import { ModalTransactionCosignatureTs } from './ModalTransactionCosignatureTs';
export default class ModalTransactionCosignature extends ModalTransactionCosignatureTs {}
