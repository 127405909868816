








// @ts-ignore
import { DashboardHomePageTs } from './DashboardHomePageTs';
export default class DashboardHomePage extends DashboardHomePageTs {}
