

















import { ActionDisplayTs } from './ActionDisplayTs';
export default class ActionDisplay extends ActionDisplayTs {}
