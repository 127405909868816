



































// @ts-ignore
import { TransactionTableTs } from './TransactionTableTs';
export default class TransactionTable extends TransactionTableTs {}
