









import { PageTitleTs } from './PageTitleTs';
export default class PageTitle extends PageTitleTs {}
