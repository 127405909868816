










import { ModalTransactionDetailsTs } from './ModalTransactionDetailsTs';
export default class ModalTransactionDetails extends ModalTransactionDetailsTs {}
