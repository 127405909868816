

























import { ModalAddedToBlacklistPopupTs } from './ModalAddedToBlacklistPopupTs';
export default class ModalAddedToBlacklistPopup extends ModalAddedToBlacklistPopupTs {}
