



























import { ModalTransactionExportTs } from './ModalTransactionExportTs';

export default class ModalTransactionExport extends ModalTransactionExportTs {}
