































// @ts-ignore
import { TransactionStatusFilterTs } from './TransactionStatusFilterTs';
export default class TransactionStatusFilter extends TransactionStatusFilterTs {}
