















































// @ts-ignore
import { TransactionListTs } from './TransactionListTs';
export default class TransactionList extends TransactionListTs {}
