















































































// @ts-ignore
import { TransactionRowTs } from './TransactionRowTs';

export default class TransactionRow extends TransactionRowTs {}
