









import { Component, Prop, Vue } from 'vue-property-decorator';
import { officialIcons } from '@/views/resources/Images';

export type AlertType = 'success' | 'warning' | 'danger';

@Component
export default class Alert extends Vue {
    @Prop({ default: 'warning' }) readonly type: AlertType;
    @Prop() readonly value: string;
    @Prop({ default: true }) readonly visible: boolean;

    private ImageWarning = officialIcons.warningWhite;
    private ImageInfo = officialIcons.infoWhite;

    get rootClass(): string {
        return 'color-' + this.type;
    }

    get isIconShown(): boolean {
        return this.visible && (this.type === 'warning' || this.type === 'danger' || this.type === 'success');
    }

    get iconSrc(): boolean {
        return this.type === 'danger' ? this.ImageWarning : this.ImageInfo;
    }
}
