








import { HardwareConfirmationButtonTs } from './HardwareConfirmationButtonTs';

export default class HardwareConfirmationButton extends HardwareConfirmationButtonTs {}
