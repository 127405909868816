














import QRCodeDisplayTs from './QRCodeDisplayTs';
export default class QRCodeDisplay extends QRCodeDisplayTs {}
