




































import { ModalAddNewContactTs } from './ModalAddNewContactTs';
export default class ModalAddNewContact extends ModalAddNewContactTs {}
